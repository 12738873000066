import ManagementPage from '@/views/ManagementPage/ManagementPage.vue'

import AccountPage from '@/views/ManagementPage/Tabs/AccountPage/AccountPage.vue'
import PerformancePage from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/PerformancePage.vue'
import IntegrationsPage from '@/views/ManagementPage/Tabs/AccountPage/Tabs/IntegrationsPage/IntegrationsPage.vue'
import IntegrationsList from '@/views/ManagementPage/Tabs/AccountPage/Tabs/IntegrationsPage/Tabs/IntegrationsList/IntegrationsList.vue'
import IntegrationView from '@/views/ManagementPage/Tabs/AccountPage/Tabs/IntegrationsPage/Tabs/IntegrationView/IntegrationView.vue'

import EngagementPage from '@/views/ManagementPage/Tabs/AccountPage/Tabs/EngagementPage/EngagementPage.vue'

import PeopleAndGroupPage from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/PeopleAndGroupPage.vue'
import PeoplePage from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/PeoplePage.vue'
import NewPeople from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/NewPerson/NewPerson.vue'
import ViewPeople from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/ViewPerson/ViewPerson.vue'

import GroupsPage from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/GroupsPage.vue'
import NewGroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/views/NewGroup/NewGroup.vue'
import ViewGroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/views/ViewGroup/ViewGroup.vue'

import DemographicAttributesPage from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/DemographicAttributesPage/DemographicAttributesPage.vue'

import EntityOkrForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrForm/EntityOkrForm.vue'
import CycleForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/CycleForm/CycleForm.vue'
import CustomField from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/CustomFieldForm/CustomFieldForm.vue'
import DefaultFieldForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/DefaultFieldForm/DefaultFieldForm.vue'

import MetadataGroupForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/MetadataGroupForm/MetadataGroupForm.vue'
import PlannedForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/PlannedForm/PlannedForm.vue'

import ManagementCycleForm from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ManagementCycleForm/ManagementCycleForm.vue'

import PermissionsPage from '@/views/ManagementPage/Tabs/PermissionsPage/PermissionsPage.vue'

import DataPage from '@/views/ManagementPage/Tabs/DataPage/DataPage.vue'
import AuditLogsPage from '@/views/ManagementPage/Tabs/DataPage/Tabs/AuditLogsPage/AuditLogsPage.vue'

import * as _permissions from '@/helpers/ability/permissions'

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login/LoginPage.vue'),
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'ManagementPage' },
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: { name: 'ManagementPage' },
  },

  {
    path: '/',
    name: 'ManagementPage',
    component: ManagementPage,
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        path: 'people-and-groups',
        name: 'PeopleAndGroupPage',
        component: PeopleAndGroupPage,
        redirect: { name: 'PeoplePage' },
        meta: {
          title: 'Routes.people-and-groups',
        },

        children: [
          {
            path: 'people',
            name: 'PeoplePage',
            component: PeoplePage,
            meta: {
              requiresPermission: _permissions.management_users,
              title: 'Routes.people-and-groups-people',
            },

            children: [
              {
                path: 'view-person/:personId',
                name: 'ViewPerson',
                component: ViewPeople,
              },
              {
                path: 'new-person',
                name: 'NewPerson',
                component: NewPeople,
              },
            ],
          },
          {
            path: 'groups',
            name: 'GroupsPage',
            component: GroupsPage,
            meta: {
              requiresPermission: _permissions.management_groups,
              title: 'Routes.groups',
            },

            children: [
              {
                path: ':key',
                name: 'GroupsForm',
                component: NewGroup,
              },
              {
                path: 'view-group/:groupId',
                name: 'ViewGroup',
                component: ViewGroup,
              },
            ],
          },
          {
            path: 'demographic-attributes',
            name: 'DemographicAttributesPage',
            component: DemographicAttributesPage,
            meta: {
              requiresPermission: _permissions.management_attributes,
              title: 'Routes.demographic-attributes',
            },

            children: [
              {
                path: ':action/:id?',
                name: 'ActionDemographicAttributesPage',
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        name: 'AccountPage',
        component: AccountPage,
        redirect: { name: 'IntegrationsPage' },
        meta: {
          title: 'Routes.account',
        },

        children: [
          {
            path: 'integrations',
            name: 'IntegrationsPage',
            component: IntegrationsPage,
            redirect: { name: 'IntegrationsList' },
            meta: {
              requiresPermission: _permissions.management_integrations,
              title: 'Routes.integrations',
            },

            children: [
              {
                path: 'all',
                name: 'IntegrationsList',
                component: IntegrationsList,
              },
              {
                path: ':integrationID',
                name: 'IntegrationView',
                component: IntegrationView,
                props: true,
              },
            ],
          },
          {
            path: 'engagement',
            name: 'EngagementPage',
            component: EngagementPage,
            meta: {
              requiresPermission: _permissions.management_engagement,
              title: 'Routes.engagement',
            },
          },
          {
            path: 'performance',
            name: 'PerformancePage',
            component: PerformancePage,
            meta: {
              requiresPermission: _permissions.management_performance,
              title: 'Routes.performance',
            },

            children: [
              {
                path: 'entity-okr/:entityType/:entityClass?',
                name: 'EntityOkrForm',
                component: EntityOkrForm,

                children: [
                  {
                    path: 'custom-field/:id',
                    name: 'CustomFieldForm',
                    component: CustomField,
                  },
                  {
                    path: 'default-field/:id',
                    name: 'DefaultFieldForm',
                    component: DefaultFieldForm,
                  },
                  {
                    path: 'metadata-group/:id',
                    name: 'MetadataGroupForm',
                    component: MetadataGroupForm,
                  },
                  {
                    path: 'planned/:id',
                    name: 'plannedForm',
                    component: PlannedForm,
                  },
                ],
              },
              {
                path: 'new-cycle/:id?',
                name: 'NewCycle',
                component: CycleForm,
              },
              {
                path: 'edit-cycle/:id',
                name: 'EditCycle',
                component: CycleForm,
              },
              {
                path: 'management-cyle',
                name: 'ManagementCycle',
                component: ManagementCycleForm,
              },
            ],
          },
        ],
      },
      {
        path: 'permissions',
        name: 'PermissionsPage',
        component: PermissionsPage,
        meta: {
          requiresPermission: _permissions.management_permissions,
          title: 'Routes.permissions',
        },
      },
      {
        path: 'data',
        name: 'DataPage',
        component: DataPage,
        redirect: { name: 'AuditLogsPage' },
        meta: {
          title: 'Routes.data',
        },

        children: [
          {
            path: 'audit-logs',
            name: 'AuditLogsPage',
            component: AuditLogsPage,
            meta: {
              requiresPermission: _permissions.management_audit_logs,
              title: 'Routes.audit-logs',
            },
          },
        ],
      },
    ],
  },
]
