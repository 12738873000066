<template>
  <div
    id="performance-page"
    class="page"
    :class="{ 'page-margin': !hideHeaders }"
    data-app
  >
    <div v-show="!hideHeaders" class="page-title">
      <PageTitle
        :subtitle="$t('ManagementPage.subtitle')"
        :title="$t('ManagementPage.title')"
      ></PageTitle>

      <div
        v-if="_canAction"
        id="add-new-btn"
        data-test-title-action-button
        class="add-new-btn add-new-btn-size add-new-btn-primary"
        data-test-add-new-btn
        @click="pageAddButtonAction"
      >
        <v-icon dark small>mdi-plus</v-icon>
        {{ pageAddButtonLabel }}
      </div>
    </div>

    <PageNavigation
      v-show="!hideHeaders"
      class="page-navigation"
      :routes="_routes"
      @change="handleNavigation"
    ></PageNavigation>

    <AlertBar ref="AlertBar"></AlertBar>

    <router-view
      v-if="_isSignedIn"
      :quick-register="quickRegister"
      :close-side-quick-form="closeGroupsForm"
    ></router-view>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import { genPageNavigationItens } from '@/helpers/menu-itens'

import * as _permissions from '@/helpers/ability/permissions'
import _EventBus from '@/plugins/eventBus'

const quickRegisterDefaultState = () => ({
  actionSelected: {
    title: '',
    image: '',
    confirmButton: '',
    action: '',
  },
  headerButtons: [],
  footerButtons: [],
  popoverOptions: [],
  show: false,
})

export default {
  name: 'ManagementPage',

  provide() {
    return {
      hiddenOverflow: this.hiddenOverflow,
      configSideQuickRegister: this.configSideQuickRegister,
      handleHideHeaders: this.handleHideHeaders,
      openGroupsForm: this.openGroupsForm,
      closeGroupsForm: this.closeGroupsForm,
      closeSideQuickForm: this.closeSideQuickForm,
      openSideQuickForm: this.openSideQuickForm,
      fetchAccount: this.fetchAccount,
      setSideQuickConfig: this.setSideQuickConfig,
    }
  },

  data() {
    return {
      hideHeaders: false,
      quickRegister: quickRegisterDefaultState(),
      pageAddButtonLabel: '',
      pageAddButtonAction: () => {},
      menuOption: null,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'currentPage',
      _isSignedIn: 'isSignedIn',
    }),

    _canAction() {
      return this.canCreate(this.$route.matched)
    },

    _canManagementUsersCreate() {
      return this.$can('access', _permissions.management_users_create)
    },
    _canManagementUsersEdit() {
      return this.$can('access', _permissions.management_users_edit)
    },
    _canManagementUsersDelete() {
      return this.$can('access', _permissions.management_users_delete)
    },
    _canManagementUsersInactivate() {
      return this.$can('access', _permissions.management_users_inactivate)
    },
    _canManagementUsersActivate() {
      return this.$can('access', _permissions.management_users_activate)
    },
    _canManagementUsersResent() {
      return this.$can('access', _permissions.management_users_resent)
    },

    _canManagementGroupsCreate() {
      return this.$can('access', _permissions.management_groups_create)
    },
    _canManagementGroupsEdit() {
      return this.$can('access', _permissions.management_groups_edit)
    },
    _canManagementGroupsActivate() {
      return this.$can('access', _permissions.management_groups_activate)
    },
    _canManagementGroupsInactivate() {
      return this.$can('access', _permissions.management_groups_inactivate)
    },
    _canManagementGroupsDelete() {
      return this.$can('access', _permissions.management_groups_delete)
    },

    _canManagementPremissionsCreate() {
      return this.$can('access', _permissions.management_permissions_create)
    },

    _canManagementAttributesCreate() {
      return this.$can('access', _permissions.management_attributes_create)
    },

    _routes() {
      const routes = genPageNavigationItens()
      return this.calcRoutesByPermissions(routes)
    },
  },

  watch: {
    'quickRegister.show': {
      handler(value) {
        this.hiddenOverflow(value)
      },
    },
    '$route.path': {
      handler() {
        this.calcPageAction()
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    _EventBus.$on('bus:alert', this.displayAlert)
    this.hiddenOverflow(false)
    this.fetchAccount()
  },

  beforeDestroy() {
    _EventBus.$off('bus:alert')
    this.hiddenOverflow(false)
  },

  methods: {
    ...mapActions(['setVisibleLateralMenu']),
    displayAlert(payload) {
      if (!payload) return

      this.$refs.AlertBar.displayAlert(payload)
    },
    hiddenOverflow(hidden = false) {
      if (hidden) {
        document.documentElement.style.overflowY = 'hidden'
        return
      }

      document.documentElement.style.overflowY = 'auto'
    },

    handleNavigation(_path, name) {
      if (!this._isSignedIn) return

      if (this.$router.currentRoute.name !== name) {
        this.$router.push({ name }).catch(() => {})
      }
    },

    getViewLinkPermissioned(link) {
      if (!link?.permissioned) return true

      if (typeof link.permissioned === 'string') {
        return this.$can('access', link.permissioned)
      }

      if (Array.isArray(link.permissioned)) {
        return link.permissioned.some(perm => this.$can('access', perm))
      }

      if (typeof link.permissioned === 'object') {
        let canAccess = false

        Object.entries(link?.permissioned).forEach(([key, value]) => {
          if (key === '_some') {
            canAccess = value.some(perm => this.$can('access', perm))
          }

          if (canAccess && key === '_and_some') {
            canAccess = value.some(perm => this.$can('access', perm))
          }
        })

        return canAccess
      }

      return false
    },
    calcRoutesByPermissions(routes) {
      const filterChildren = children => {
        return children.filter(child => {
          return this.getViewLinkPermissioned(child)
        })
      }

      const filterRoutes = page => {
        if (!this.getViewLinkPermissioned(page)) {
          return null
        }

        if (Array.isArray(page?.children)) {
          page.children = filterChildren(page.children)

          if (!page.children.length) {
            return null
          }
        }

        return page
      }

      const routesByPermissions = structuredClone(routes)
        .map(filterRoutes)
        .filter(Boolean)

      return routesByPermissions
    },

    handleHideHeaders(state) {
      this.hideHeaders = state
    },

    fetchAccount() {
      this.$store.dispatch(
        'currentAccount/fetch',
        this.$t('GroupsPage.peopleCount')
      )
    },

    calcPageAction() {
      const pageNames = {
        GroupsPage: this.setGroupsAction,
        PeoplePage: this.setPeopleAction,
        DemographicAttributesPage: this.setDemographicAttributesPageAction,
      }
      const action = Object.entries(pageNames)
        .find(([name]) =>
          this.$route.matched.findLast(record => record.name === name)
        )
        ?.pop()

      if (action) {
        action()
        return
      }

      this.setDefaultAction()
    },
    setGroupsAction() {
      this.pageAddButtonLabel = this.$t('GroupsPage.addNew')
      this.setVisibleLateralMenu(true)
      this.pageAddButtonAction = () => {
        this.openGroupsForm({
          key: 'new',
          callback: null,
          action: 'addGroup',
        })
      }
    },
    setPeopleAction() {
      this.pageAddButtonLabel = this.$t('PeoplePage.addNewPersonBtn')
      this.handleHideHeaders(false)
      this.setVisibleLateralMenu(true)
      this.pageAddButtonAction = () => {
        this.openSideQuickForm('newPerson')
      }
    },
    setDemographicAttributesPageAction() {
      this.pageAddButtonLabel = this.$t('DemographicAttributesPage.action-new')
      this.pageAddButtonAction = () => {
        this.$router
          .push({
            name: 'ActionDemographicAttributesPage',
            params: {
              action: 'new',
            },
          })
          .catch(() => {})
      }
    },
    setDefaultAction() {
      this.handleHideHeaders(false)
      this.setVisibleLateralMenu(false)
      this.pageAddButtonLabel = ''
      this.pageAddButtonAction = () => {}
    },

    canCreate(matched = this.$route.matched) {
      const pageNames = {
        GroupsPage: this._canManagementGroupsCreate,
        PeoplePage: this._canManagementUsersCreate,
        GroupPermissionsPage: this._canManagementPremissionsCreate,
        DemographicAttributesPage: this._canManagementAttributesCreate,
      }

      const canAction = Object.entries(pageNames)
        .find(([name]) => matched.findLast(record => record.name === name))
        ?.pop()

      if (canAction) {
        return canAction
      }

      return false
    },

    getPopoverOptionsGroup(inactive = false) {
      const actions = [
        {
          permission:
            this._canManagementGroupsEdit || this._canManagementGroupsActivate,
          status: inactive,
          name: this.$t('GroupsPage.viewGroup.popoverOptions.active'),
          icon: 'fi fi-rr-power',
          action: 'active',
        },
        {
          permission:
            this._canManagementGroupsEdit ||
            this._canManagementGroupsInactivate,
          status: !inactive,
          name: this.$t('GroupsPage.viewGroup.popoverOptions.inactive'),
          icon: 'fi fi-br-power',
          action: 'inactive',
        },
        {
          permission: this._canManagementGroupsDelete,
          status: true,
          name: this.$t('GroupsPage.viewGroup.popoverOptions.delete'),
          icon: 'fi fi-rr-trash',
          action: 'delete',
        },
      ]

      const items = actions.filter(action => action.permission && action.status)

      return items
    },
    getPopoverOptionsPeople(personStatus = this.menuOption) {
      const isInvitedStatus = personStatus === 'invited'
      const isConfirmedStatus = personStatus === 'confirmed'
      const isInactiveStatus = personStatus === 'inactive'

      const actions = [
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersResent,
          status: isInvitedStatus,
          name: this.$t('PeoplePage.viewPerson.popoverOptions.resendInvite'),
          icon: 'fi-rr-paper-plane',
          action: 'resendInvite',
        },
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersActivate,
          status: isInactiveStatus,
          name: this.$t('PeoplePage.viewPerson.popoverOptions.active'),
          icon: 'fi-rr-power',
          action: 'active',
        },
        {
          permission:
            this._canManagementUsersEdit || this._canManagementUsersInactivate,
          status: isConfirmedStatus || isInvitedStatus,
          name: this.$t('PeoplePage.viewPerson.popoverOptions.inactive'),
          icon: 'fi-rr-power',
          action: 'inactive',
        },
        {
          permission: this._canManagementUsersDelete,
          status: true,
          name: this.$t('PeoplePage.viewPerson.popoverOptions.delete'),
          icon: 'fi-rr-trash',
          action: 'delete',
        },
      ]

      const items = actions.filter(action => action.permission && action.status)

      return items
    },

    closeGroupsForm() {
      this.quickRegister.show = false

      if (this.$route.name === 'GroupsPage') return
      this.$router.push({ name: 'GroupsPage' })
    },
    openGroupsForm(payload) {
      this.configSideQuickRegister(payload.action)
      this.quickRegister.show = true

      const route = {
        name: 'GroupsForm',
        params: payload,
        query: {},
      }
      if (payload.target && payload.target.id)
        route.query.parentGroupID = payload.target.id

      this.$router.push(route)
    },
    closeSideQuickForm(route) {
      this.quickRegister = {
        show: false,
        actionSelected: {
          title: '',
          image: '',
          confirmButton: '',
          action: '',
        },
        headerButtons: [],
        footerButtons: [],
        popoverOptions: [],
      }

      if (route)
        return this.$router.push({ name: route }).catch(() => {
          /* err */
        })

      this.$router.go(-1)
    },
    openSideQuickForm(action, id = 'new', index, menuOption) {
      this.menuOption = menuOption ? menuOption.access.id : menuOption

      this.configSideQuickRegister(action, id)
      this.quickRegister.show = true
      const rota = this.$route.name

      if (action == 'newPerson') {
        rota != 'NewPerson' &&
          this.$router.push({ name: 'NewPerson' }).catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          })
      }

      if (action == 'viewPerson') {
        rota != 'ViewPerson' &&
          this.$router
            .push({
              name: 'ViewPerson',
              params: { personId: id },
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log(err)
            })
      }
    },
    configSideQuickRegister(action, id, payload = undefined) {
      if (action === 'newPerson' || action === 'viewPerson')
        return this.handlePersonForm(action, payload)
      if (action === 'addGroupPermission' || action === 'editGroupPermission')
        return this.handleGroupPermissionForm(action)
      if (action === 'addGroup' || action === 'viewGroup')
        return this.handleGroupForm(action, id)
    },
    handlePersonForm(action, personStatus) {
      if (action === 'newPerson') {
        this.quickRegister.actionSelected = {
          title: this.$t('PeoplePage.newPerson.title'),
          image: this.$t('PeoplePage.newPerson.image'),
          action: 'createPerson',
        }
        this.quickRegister.footerButtons = [
          {
            name: this.$t('PeoplePage.newPerson.cancelLabel'),
            action: 'close',
            type: 'text',
          },
          {
            name: this.$t('PeoplePage.newPerson.inviteLaterLabel'),
            action: 'inviteLater',
            type: 'neutral',
          },
          {
            name: this.$t('PeoplePage.newPerson.inviteLabel'),
            action: 'invite',
            type: 'default',
          },
        ]
      }

      if (action === 'viewPerson') {
        this.quickRegister.actionSelected = {
          title: this.$t('PeoplePage.viewPerson.title'),
          image: this.$t('PeoplePage.viewPerson.image'),
          action: 'viewPerson',
        }

        this.quickRegister.popoverOptions =
          this.getPopoverOptionsPeople(personStatus)
      }
    },
    handleGroupPermissionForm(action) {
      if (action === 'addGroupPermission') {
        this.quickRegister.actionSelected = {
          title: this.$t('AddGroupPermissionForm.title'),
          confirmButton: this.$t('AddGroupPermissionForm.confirmButton'),
          image: this.$t('AddGroupPermissionForm.image'),
          action: action,
        }
        this.quickRegister.footerButtons = [
          {
            name: this.$t('AddGroupPermissionForm.cancelButton'),
            action: 'close',
            type: 'text',
          },
          {
            name: this.quickRegister.actionSelected.confirmButton,
            action: 'submit',
            type: 'default',
          },
        ]
        this.quickRegister.headerButtons = []
      }

      if (action === 'editGroupPermission') {
        this.quickRegister.actionSelected = {
          title: this.$t('EditGroupPermissionForm.title'),
          confirmButton: this.$t('EditGroupPermissionForm.confirmButton'),
          image: this.$t('EditGroupPermissionForm.image'),
          action: 'editGroupPermission',
        }
        this.quickRegister.footerButtons = []
        this.quickRegister.headerButtons = []
      }
    },
    handleGroupForm(action, group) {
      if (action === 'addGroup') {
        this.quickRegister.actionSelected = {
          title: this.$t('GroupsPage.addNewGroup.title'),
          confirmButton: this.$t('GroupsPage.addNewGroup.addButtonLabel'),
          image: 'details',
          action: action,
        }
        this.quickRegister.footerButtons = [
          {
            name: this.$t('GroupsPage.cancelButton'),
            action: 'close',
            type: 'text',
          },
          {
            name: this.quickRegister.actionSelected.confirmButton,
            action: 'submit',
            type: 'default',
          },
        ]
        this.quickRegister.headerButtons = []
      }

      if (action === 'viewGroup') {
        this.quickRegister.footerButtons = []
        this.quickRegister.actionSelected = {
          title: this.$t('GroupsPage.viewGroup.title'),
          image: this.$t('GroupsPage.viewGroup.image'),
          action: 'viewGroup',
        }

        this.quickRegister.popoverOptions = this.getPopoverOptionsGroup(
          group.inactive
        )

        this.$router
          .push({
            name: 'ViewGroup',
            params: { groupId: group.id },
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          })
      }
    },
    setSideQuickConfig(
      show,
      action = null,
      footer = null,
      header = null,
      popover = null
    ) {
      this.quickRegister.show = show
      if (show) {
        if (action) this.quickRegister.actionSelected = action
        if (footer) this.quickRegister.footerButtons = footer
        if (header) this.quickRegister.headerButtons = header
        if (popover) this.quickRegister.popoverOptions = popover
      } else {
        this.quickRegister = quickRegisterDefaultState()
      }
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
